.OrdersContainer {
  width: 100vw;
  // height: 60vh; // We need to addd this once we finish edit
  display: table;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.OrdersTab {
  border-radius: 0.5em;
  background: rgba(255, 255, 255, 0.945);
  margin-inline: 4em;
  margin-top: 4em;
  margin-bottom: 1.2em;
}

.TableCells {
  justify-content: center;
  vertical-align: middle;
}
