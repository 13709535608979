@mixin HorizontalAlignment {
  display: flex;
  flex-direction: row;
}

.root {
  @include HorizontalAlignment();
  background: gray;
  background: white;
}
