.Card {
  position: absolute;
  top: -2em;
  left: 1.5em;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  width: 6rem;
  height: 6rem;
  opacity: 1;
  background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
  color: rgb(255, 255, 255);
  border-radius: 0.75rem;
  box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem,
    rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.Divider {
  flex-shrink: 0;
  border-top: 0px solid rgba(0, 0, 0, 0.12);
  border-right: 0px solid rgba(0, 0, 0, 0.12);
  border-left: 0px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  height: 0.3rem;
  border-bottom: none;
  opacity: 0.25;
  background-image: linear-gradient(
    to right,
    rgba(52, 71, 103, 0),
    rgba(52, 71, 103, 0.4),
    rgba(52, 71, 103, 0)
  ) !important;
}

.CustomCard {
  position: relative;
  height: 12rem;
  width: 50%;
  background-color: white;
  // background-color: blueviolet;
  border-radius: 0.75em;
  justify-content: space-between;
  margin-bottom: 4em;

  &:hover {
    -webkit-box-shadow: 10px 10px 31px -13px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 10px 10px 31px -13px rgba(0, 0, 0, 0.6);
    box-shadow: 10px 10px 31px -13px rgba(0, 0, 0, 0.6);
    bottom: 1.5em;

    cursor: pointer;
  }

  :hover > * {
    pointer-events: none;
  }
  .Body {
    height: 100%;
  }
  .Heading {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1.3rem;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(123, 128, 154);
    font-weight: 300;
  }

  .SubHeading {
    font-size: 2.5rem;
    line-height: 1.375;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 700;
    letter-spacing: 0.00735em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(52, 71, 103);
  }
  .FooterContainer {
    margin-left: 1.5em;
    font-size: 1.2rem;
    // .FooterPart1 {
    //   color: "green";
    //   margin-right: "0.5em";
    // }
  }
}

.Logo {
  user-select: none;
  width: 1em;
  height: 1em;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  flex-shrink: 0;
  font-size: 2.2rem;
}

// Phones and tablets
@media screen and (min-width: 270px) and (max-width: 995px) {
  .Card {
    width: 4.5rem;
    height: 4.5rem;
  }
  .CustomCard {
    width: 100%;
    margin-right: 2em;
    .Heading {
      font-size: 1rem;
    }
    .SubHeading {
      font-size: 2rem;
    }
    .FooterContainer {
      margin-left: 0.5em;
    }
  }
}
