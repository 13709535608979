@import "./Color.scss";

@mixin HorizontalAlignment {
  display: flex;
  flex-direction: row;
}

.root {
  @include HorizontalAlignment();
  background: gray;
  background: $blue;
}
