// Colors
$white: #fff;
$whitesmoke: #f5f5f5;
$lightest-grey: #e5e5e5;
$lighter-grey: #d5d5d5;
$light-grey: #c5c5c5;
$grey: #888;
$dark-grey: #4a4a4a;
$black: #000;

$gold: #ffd700;
$lightblue: #add8e6;

// Mern colors
$mern-primary: #e3fc02;
$mern-hover: #323232;

$light-opacity: rgba(0, 0, 0, .4);

// Primary Colors
$primary: $turquoise;
$info: $blue;
$success: $green;
$warning: $yellow;
$danger: $red;
$dark: $grey-darker;
$text: $grey-dark;

// Font Weight
$weight-light: 200;
$weight-normal: 400;
$weight-semibold: 600;
$weight-bold: 800;
