.SmartTagContainer {
  width: 100vw;
  // height: 60vh; // We need to addd this once we finish edit
  padding: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.OrdersTab {
  border-radius: 0.5em;
  background: rgba(255, 255, 255, 0.945);
  margin-inline: 4em;
  margin-top: 4em;
  margin-bottom: 1.2em;
}

.TableCells {
  justify-content: center;
  vertical-align: middle;
}


.qr-img{
  margin: 0 auto; /* Centers the container */
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  align-items: center; 
  width: 50%;
  height: 50%;
  
}

.popup {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  }
  
  .popup-inner {
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
  width: 90%;
  }

  @media screen and (min-width: 768px) {
    .container {
      width: 40%;
    }
  }
  
  .popup-inner h2 {
  margin-top: 0;
  }
  
  .popup-inner label {
  display: block;
  margin-bottom: 10px;
  }
  
  .popup-inner input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  }

  
  .popup-inner button[type="submit"],
  .popup-inner button[type="button"] {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 auto; /* Centers the container */
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  align-items: center; /* Centers the button vertically */
  }
  
  .popup-inner button[type="submit"]:hover,
  .popup-inner button[type="button"]:hover {
  background-color: #45a049;

  }
  
  .popup-inner button[type="submit"] {
  margin-top: 20px;
  }
  
  .popup-inner button[type="button"] {
  margin-top: 20px;
  background-color: #f44336;
  }
  
  .popup-inner button[type="button"]:hover {
  background-color: #da190b;
  }
  
  .popup-inner button[type="submit"]:disabled {
  background-color: #bfbfbf;
  color: #ffffff;
  cursor: not-allowed;
  }
  
  .popup-inner button[type="button"]:disabled {
  background-color: #bfbfbf;
  color: #ffffff;
  cursor: not-allowed;
  }