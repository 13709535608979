.LogoColumn {
  width: 120px;
  vertical-align: middle;
}
.StatusColumn {
  width: 100px;
  vertical-align: middle;
}

.ProductColumn,
.ActionColumn,
.InventoryColumn,
.TypeColumn,
.VendorColumn,
.ActionColumn {
  width: 200px;
  vertical-align: middle;
}
