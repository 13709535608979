.ProductsContainer {
  width: 100vw;
  // height: 60vh; // We need to addd this once we finish edit
  display: table;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.ProductsTab {
  border-radius: 0.5em;
  background: rgba(255, 255, 255, 0.945);
  margin-inline: 4em;
  margin-top: 2.4em;
  margin-bottom: 1.2em;
}

.ant-table-thead .ant-table-cell {
  background-color: #232f3e !important;
  color: white !important;
  text-align: center !important;
}

.ant-table-column-sorter .ant-table-column-sorter-inner {
  color: white !important;
}

.StatusColumn {
  text-align: center;
  vertical-align: middle;
}
.ActiveStatus {
  background: green;
  text-align: center;
  border-radius: 80%;
}

.DraftStatus {
  background: #d4380d;
  text-align: center;
  border-radius: 80%;
}

@media screen and (min-width: 750px) and (max-width: 1200px) {
  .ProductsContainer {
    margin-top: 7em;
  }
}
