@import "../../../styles/scss/Theme/Colors.scss";
@import "../../../styles/scss/Theme/mixins.scss";
.List {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20vw;
  height: 100vh;
  margin-top: 1.7em;
  background-color: aquamarine;
}

.Element {
  @include HorizontalAlignment();
  text-decoration: none;
  color: black;
  align-items: center;
  margin-top: 1.5em;
  font-size: 30;
  width: 95%;
  height: 2.5em;
  border-radius: 0.5em;
  background-color: white;
  &:hover {
    color: $primary;
    background-color: pink;
  }

  &.active {
    background-color: pink;
    color: $primary;
  }
}
