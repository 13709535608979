.DashboardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  margin-inline: 1em;
  align-items: center;
  color: white;
}

.LineChart {
  width: 70%;
  height: 50%;
  margin-bottom: 5em;
  background: white;
}

@media screen and (min-width: 270px) and (max-width: 480px) {
  .LineChart {
    width: 80%;
    height: 70%;
  }
}
