.OrdersContainer {
  width: 100vw;
  display: table;
  flex-direction: column;
  text-align: left;
  padding: 10px;
  
  display: inline-block;
}

.OrdersTab {
  border-radius: 0.5em;
  background: rgba(255, 255, 255, 0.945);
  margin-inline: 4em;
  margin-top: 4em;
  margin-bottom: 1.2em;
}

.TableCells {
  text-align: left;
  vertical-align: middle;
  min-width: 90px; 
}
